import React, {
  useRef,
  useState,
  createRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Link from '../../utils/link';
import { logAmplitude } from '../../utils/amplitude';
import { navigationCSS } from '../../theme/page';

import { getNavigationProps, getNavigation } from '../../utils/navigation';

const navAnchorStyles = (props) => css`
  color: ${props.theme.color.white};
  display: block;
  font-size: ${props.theme.modularScale.small};
  text-decoration: none;

  @media (min-width: ${props.theme.breakpoint.medium}) {
    color: ${(props.isHighlighted ? props.theme.color.brandA11yRed : props.theme.color.brand)};

    &::after {
      border: 0.5rem solid transparent;
      border-top-color: ${(props.isHighlighted ? props.theme.color.brandA11yRed : props.theme.color.brand)};
      bottom: 0;
      content: '';
      left: 50%;
      opacity: 0;
      position: absolute;
      top: calc(100% - 0.6rem);
      transform-origin: 50% 25%;
      transform: translate(-50%, 0.5rem) rotate(180deg);
      transition: opacity ${props.theme.animation.default} ${props.theme.animation.fast},
                  transform ${props.theme.animation.default} ${props.theme.animation.easeOutQuart} ${props.theme.animation.fast}
    }

    :hover {
      color: ${(props.isHighlighted ? `${props.theme.color.brandA11yRed}80` : props.theme.color.brandLight)};
    }
  }
`;

const NavLink = styled.a`
  ${navAnchorStyles};
`;

const NavLabel = styled.label`
  ${navAnchorStyles}
  transition: background-image ${(props) => props.theme.animation.fast} ${(props) => props.theme.animation.easeInQuad};
`;

const FILTERED_PRIMARYITEM_EMO_PROPS = new Set(['flair', 'isHighlighted', 'maxHeight']);

const PrimaryItem = styled('li', {
  shouldForwardProp: (prop) => !FILTERED_PRIMARYITEM_EMO_PROPS.has(prop)
})`
  border-top: 1px solid ${(props) => props.theme.color.white};

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.color.white};
  }

  > ${NavLink} {
    display: none;
  }

  > ${NavLink}, > ${NavLabel} {
    ${(props) => props.theme.arrow('down', props.theme.color.white, 'right center', 5)}
    font-family: ${(props) => props.theme.font.caption};
    letter-spacing: 0.24em;
    line-height: 4.4rem;
    position: relative;
    text-transform: uppercase;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    border: 0 none;

    &:hover, :focus-within {
      > ul {
        max-height: ${(props) => props.maxHeight};
        visibility: visible;
      }

      > ${NavLink} {
        &::after {
          transform: translate(-50%) rotate(180deg);
          opacity: 1;
        }
      }
    }

    > ${NavLabel} {
      display: none;
    }

    > ${NavLink} {
      background: none;
      display: block;
      line-height: 5rem;
      transition: color ${(props) => props.theme.animation.default};
      position: relative;
    }

    ${({ flair, isHighlighted, theme }) => (flair ? css`
      background-image: url(${process.env.NEXT_PUBLIC_ASSET_HOST}/images/wings-left${!isHighlighted && '-blue'}.svg), url(${process.env.NEXT_PUBLIC_ASSET_HOST}/images/wings-right${!isHighlighted && '-blue'}.svg);
      background-position: 0, 100%;
      background-repeat: no-repeat;
      background-size: 12px;
      padding: 0 ${theme.modularScale.base};
    ` : null)}
  }
`;

const PrimaryTrigger = styled.input`
  opacity: 0;
  width: 0;
  position: absolute;

  @media (max-width: ${(props) => props.theme.breakpoint.max('medium')}) {
    &:checked {
      ~ ${NavLabel} {
        ${(props) => props.theme.arrow('up', props.theme.color.white, 'right center', 5)}
      }

      ~ ul {
        max-height: ${(props) => props.childCount * 4}rem;
        visibility: visible;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    display: none;

    ~ ul {
      max-height: 0;
    }
  }
`;

const PrimaryList = styled.ul`
  padding: 5rem 3rem 10rem 3rem;
  // tertiary mobile elements cause overflow scrolling on x axis, set to hidden
  overflow-x: hidden;
  z-index: ${(props) => props.theme.layers.upstage};
  height: 100%;

  > li > ul {
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: max-height ${(props) => props.theme.animation.fast} ${(props) => props.theme.animation.easeInQuad};
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    ${navigationCSS}
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    overflow: hidden;

    > li > ul {
      transition: max-height ${(props) => props.theme.animation.default} ${(props) => props.theme.animation.easeInQuart},
      visibility ${(props) => props.theme.animation.default};
    }
  }
`;

const NavigationPrimary = (props) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const nav1 = getNavigation(props.navigation, 1);
  const nav2 = getNavigation(props.navigation, 2);

  const secondaryNavigationItems = (id) => nav2.filter((x) => x.parent_id === id);
  const primaryRef = useRef([...Array(nav1?.length ?? 0)].map(() => createRef()));

  useEffect(() => {
    if (primaryRef.current[0]?.current) {
      const primary = primaryRef.current[0].current;
      const checkbox = primary.querySelector('input');
      if (checkbox && props.isActive) checkbox.focus();
    }
  }, [props.isActive]);

  const makeMagic = (i) => {
    if (!primaryRef || !primaryRef.current[i]) return;

    const primary = primaryRef.current[i].current;
    const secondary = primary.querySelector('ul');
    const height = secondary?.scrollHeight;
    if (!props.isMobile) primary.setAttribute('aria-expanded', true);
    setMaxHeight(height);
  };

  const handleExit = (i) => {
    if (!primaryRef || !primaryRef.current[i]) return;

    const primary = primaryRef.current[i].current;
    const { activeElement } = global.document;
    if (activeElement) activeElement.blur();
    if (!props.isMobile) primary.setAttribute('aria-expanded', false);
  };

  const handleNavCollapse = (event, i) => {
    const { target } = event;
    // reset and collapse desktop nav when a nav link is clicked
    // also fires on keyboard navigation selection
    if (!props.isMobile && target?.tagName === 'A') {
      handleExit(i);
      setMaxHeight(0);
    }
  };

  const trackNavClick = (item) => {
    logAmplitude('Navigated Site', {
      navigationTerm: item.name.toLowerCase(),
      path: item.navigation_url,
      navigationType: props.isMobile ? 'mobile burger menu' : 'desktop top nav'
    });
  };

  return (
    <PrimaryList role="menu">
      {
        nav1.map((item, i) => (
          <PrimaryItem
            aria-haspopup
            role="menuitem"
            flair={item.add_flair}
            isHighlighted={item.highlight}
            ref={primaryRef.current[i]}
            key={item.id}
            maxHeight={`${maxHeight}px`}
            onMouseEnter={() => makeMagic(i)}
            onMouseLeave={() => handleExit(i)}
            onFocus={() => makeMagic(i)}
            onBlur={() => handleExit(i)}
            onClick={(e) => handleNavCollapse(e, i)}
          >
            <Link
              {...getNavigationProps({ item })}
              passHref
            >
              <NavLink
                isHighlighted={item.highlight}
                onClick={() => trackNavClick(item)}
              >
                {item.name}
              </NavLink>
            </Link>

            <PrimaryTrigger
              childCount={
                nav2
                  ? nav2
                    .filter((x) => x.parent_id === item.id).length + 1
                  : 0
              }
              role="menuitemcheckbox"
              onChange={(e) => props.handleChange(e, item)}
              type="checkbox"
              id={`navigation-primary-${item.id}`}
              name="primary"
            />
            <NavLabel htmlFor={`navigation-primary-${item.id}`}>
              {item.name}
            </NavLabel>

            {
              secondaryNavigationItems(item.id).length > 0
                && secondaryNavigationItems(item.id)
                  .filter((x) => !x.has_children)
                  .length === secondaryNavigationItems(item.id).length
                /* render conditional for 2 or 3 level deep navigation */
                ? props.renderSecondaryNoChildren(item)
                : props.renderSecondary(item)
            }
          </PrimaryItem>
        ))
      }
    </PrimaryList>
  );
};

NavigationPrimary.propTypes = {
  handleChange: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  renderSecondary: PropTypes.func.isRequired,
  renderSecondaryNoChildren: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
};

NavigationPrimary.whyDidYouRender = true;

export default NavigationPrimary;
